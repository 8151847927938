import {DecimalPipe} from '@angular/common';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {BehaviorSubject} from 'rxjs';
import {ChangeType} from '../model/change/change-type';
import {TimeSeriesDataSelectionChange} from '../model/change/time-series-data-selection-change';
import {TimeSeriesId} from '../model/time-series-id';
import {TimeSeriesAggregate} from '../model/timeseries/time-series-aggregate';
import {TimeSeriesData} from '../model/timeseries/time-series-data';
import * as moment from 'moment';
import {Resolution} from '../../shared/time-series/model/resolution.enum';
import {DiagramService} from '../../shared/services/diagram.service';
import {DiagramType} from '../../admin/additional-diag-types/model/diagram-type';
import {colors} from '../../shared/constants/colors';


@Component({
    selector: 'jhi-diagram',
    templateUrl: './diagram.component.html',
    styleUrls: []
})
export class DiagramComponent implements OnInit, OnChanges {

    chart: Chart;

    @Input() minSelected: boolean;
    @Input() maxSelected: boolean;
    @Input() averageSelected: boolean;
    @Input() resolution: Resolution;
    @Input() compactSelected: boolean;
    @Input() timeSeriesDataChanges: BehaviorSubject<TimeSeriesDataSelectionChange[]>;

    addedCharts: TimeSeriesDataSelectionChange[] = [];

    Y_AXIS_ID_PREFIX = 'yAxis|';
    SERIES_ID_PREFIX = 'series|';
    LABEL_ONLY_SERIES = 'labelOnlySeries|';

    constructor(private decimalPipe: DecimalPipe,
                private diagramService: DiagramService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.chart = new Chart(this.defaultChartOptions);
        this.timeSeriesDataChanges.subscribe((timeSeriesDataChanges) => {
            this.chart?.destroy();
            this.chart = new Chart(this.defaultChartOptions);

            setTimeout(() => {
                this.removeSeries(timeSeriesDataChanges);
                this.addSeries(timeSeriesDataChanges);
                this.redraw();
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.compactSelected) {
            if (!this.chart || !this.chart.ref) {
                return;
            }
            this.chart.ref.reflow();
        }

        if (changes.minSelected) {
            if (!this.chart || !this.chart.ref) {
                return;
            }

            if (changes.minSelected.currentValue) {
                this.addedCharts.forEach((chart) => {
                    this.addAggregateToChart(chart, chart.timeSeriesData.min, 'Min ', true);
                });
            } else {
                this.addedCharts.forEach((chart) => {
                    this.removeAggregateFromChart(chart, 'Min ', true);
                });
            }
            this.redraw();
        }

        if (changes.maxSelected) {
            if (!this.chart || !this.chart.ref) {
                return;
            }

            if (changes.maxSelected.currentValue) {
                this.addedCharts.forEach((chart) => {
                    this.addAggregateToChart(chart, chart.timeSeriesData.max, 'Max ', true);
                });
            } else {
                this.addedCharts.forEach((chart) => {
                    this.removeAggregateFromChart(chart, 'Max ', true);
                });
            }
            this.redraw();
        }

        if (changes.averageSelected) {
            if (!this.chart || !this.chart.ref) {
                return;
            }

            if (changes.averageSelected.currentValue) {
                this.addedCharts.forEach((chart) => {
                    this.addAggregateToChart(chart, chart.timeSeriesData.average, 'Average ', false);
                });
            } else {
                this.addedCharts.forEach((chart) => {
                    this.removeAggregateFromChart(chart, 'Average ', false);
                });
            }
            this.redraw();
        }
    }

    private addSeries(timeSeriesDataChanges) {
        let additionChanges = timeSeriesDataChanges.filter((timeSeriesDataChange) =>
            timeSeriesDataChange.changeType === ChangeType.ADDITION && timeSeriesDataChange.timeSeriesData.timeSeries.timeInterval);
        additionChanges.forEach((additionChange) => {
            let timeSeriesData = additionChange.timeSeriesData;
            if (this.allNull(timeSeriesData)) {
                return;
            }

            let yAxis = this.setYAxis(timeSeriesData);

            this.addSeriesToDiagram(additionChange, timeSeriesData, yAxis);

            if (this.minSelected) {
                this.addAggregateToChart(additionChange, additionChange.timeSeriesData.min, 'Min ', true);
            }

            if (this.maxSelected) {
                this.addAggregateToChart(additionChange, additionChange.timeSeriesData.max, 'Max ', true);
            }

            if (this.averageSelected) {
                this.addAggregateToChart(additionChange, additionChange.timeSeriesData.average, 'Average ', false);
            }

            this.addedCharts.push(additionChange);
        });
    }

    private allNull(timeSeriesData: TimeSeriesData): boolean {
        return timeSeriesData.timeSeries.data.every((data) => data === null);
    }

    private setYAxis(timeSeriesData) {
        let yAxisId = this.Y_AXIS_ID_PREFIX + timeSeriesData.measureUnit;
        let yAxis = this.chart.ref.get(yAxisId);
        if (!yAxis) {
            this.chart.ref.addAxis({
                id: yAxisId,
                softMin: 0,
                labels: {
                    format: '{value}',
                    style: {
                        color: '#000000'
                    }
                },
                title: {
                    text: timeSeriesData.measureUnit
                },
                opposite: Boolean(this.chart.ref.yAxis.length % 2),
                plotLines: []
            }, false, false);
            yAxis = this.chart.ref.get(yAxisId);
        }
        return yAxis;
    }

    private addSeriesToDiagram(additionChange, timeSeriesData, yAxis) {
        let option:any = {
            id: this.SERIES_ID_PREFIX + TimeSeriesId.toIdString(additionChange.timeSeriesId),
            data: timeSeriesData.timeSeries.data,
            type: this.diagramService.getSeriesType(timeSeriesData.diagramType),
            name: timeSeriesData.name,
            pointStart: moment(timeSeriesData.timeSeries.timeInterval.start).valueOf(),
            pointIntervalUnit: this.diagramService.getPointIntervalUnit(timeSeriesData.timeSeries.timeInterval.resolution),
            pointInterval: this.diagramService.getPointInterval(timeSeriesData.timeSeries.timeInterval.resolution),
            yAxis: yAxis.options.index,
            color: this.getLeastUsedColor(),
            customData: {
                unit: timeSeriesData.measureUnit,
                min: timeSeriesData.min,
                max: timeSeriesData.max,
                avg: timeSeriesData.average
            },
            step: timeSeriesData.diagramType === DiagramType.LINE ? 'timeseries' : undefined
        };
        this.chart.ref.addSeries(option, false);
    }

    private getLeastUsedColor() {
        return colors.map((color, colorIndex) => {
            let occurrences = this.chart.ref.series.filter((serie) => {
                let seriesOption:any = serie.options;
                return seriesOption.color === color;
            }).length;
            return {color, occurrences, colorIndex};
        }).sort((a, b) => a.occurrences < b.occurrences || (a.occurrences === b.occurrences && a.colorIndex < b.colorIndex) ? -1 : 1)[0].color;
    }

    private removeSeries(timeSeriesDataChanges) {
        let removalChanges = timeSeriesDataChanges.filter((timeSeriesDataChange) => timeSeriesDataChange.changeType === ChangeType.REMOVAL);
        removalChanges.forEach((removalChange) => {
            let seriesToRemove:any = this.chart.ref.get(this.SERIES_ID_PREFIX + TimeSeriesId.toIdString(removalChange.timeSeriesId));
            if (seriesToRemove) {
                let yAxis = seriesToRemove.yAxis;
                let seriesWithSameYAxis = this.chart.ref.series.filter(serie => serie.yAxis.options.id == yAxis.options.id);
                seriesToRemove.remove(false);
                this.removeFromAddedCharts(removalChange.timeSeriesId);
                if (seriesWithSameYAxis.length === 1) {
                    yAxis.remove(false);
                }
            }

            if (this.minSelected) {
                this.removeAggregateFromChart(removalChange, 'Min ', true);
            }

            if (this.maxSelected) {
                this.removeAggregateFromChart(removalChange, 'Max ', true);
            }

            if (this.averageSelected) {
                this.removeAggregateFromChart(removalChange, 'Average ', false);
            }

        });

        if (removalChanges.length > 0) {
            this.rearrangeYAxes();
        }
    }

    private rearrangeYAxes() {
        let yAxes = this.chart.ref.yAxis;
        for (let i = 0; i < yAxes.length; i++) {
            yAxes[i].update({
                opposite: Boolean(i % 2)
            }, false);
        }
    }

    private redraw() {
        if (this.chart && this.chart.ref) {
            this.chart.ref.redraw();
            this.chart.ref.zoomOut();
        }
    }

    private removeFromAddedCharts(timeSeriesId: TimeSeriesId) {
        let chartIndexToRemove = TimeSeriesId.findChart(this.addedCharts.map((chart) => chart.timeSeriesId), timeSeriesId);
        this.addedCharts.splice(chartIndexToRemove, 1);
    }

    private addAggregateToChart(chart: TimeSeriesDataSelectionChange, aggregate: TimeSeriesAggregate, prefix: string, isVertical: boolean) {
        if (!aggregate) {
            return;
        }

        const plotLineId = prefix + '|' + TimeSeriesId.toIdString(chart.timeSeriesId);
        let axiss:any = this.chart.ref.get(this.SERIES_ID_PREFIX + TimeSeriesId.toIdString(chart.timeSeriesId));
        let seriesColor = axiss.color;
        const axis:any  = isVertical ? this.chart.ref.xAxis[0] : this.chart.ref.get(this.Y_AXIS_ID_PREFIX + chart.timeSeriesData.measureUnit);

        const plotLineOptions = {
            color: seriesColor,
            id: plotLineId,
            dashStyle: 'LongDash',
            width: 1,
            value: isVertical ? moment(aggregate.timeIndex) : aggregate.seriesValue,
            zIndex: 1
        };

        let extendedPlotLineOptions = Array.from(axis.options.plotLines);
        extendedPlotLineOptions.push(plotLineOptions);

        const emptySeriesForLegend:any = {
            id: this.LABEL_ONLY_SERIES + prefix + '|' + TimeSeriesId.toIdString(chart.timeSeriesId),
            color: seriesColor,
            name: prefix + chart.timeSeriesData.name,
            dashStyle: 'LongDash',
            marker: {
                enabled: false
            },
            events: {
                legendItemClick: function () {
                    // noinspection JSPotentiallyInvalidUsageOfClassThis
                    if (this.visible) {
                        axis.removePlotLine(plotLineId);
                    } else {
                        axis.addPlotLine(plotLineOptions);
                    }
                }
            }
        };
        axis.update({
            plotLines: extendedPlotLineOptions
        }, false);
        this.chart.ref.addSeries(emptySeriesForLegend, false);
    }

    private removeAggregateFromChart(chart: TimeSeriesDataSelectionChange, prefix: string, isVertical: boolean): void {
        let labelSeries = this.chart.ref.series.filter((serie) => serie.options.id === this.LABEL_ONLY_SERIES + prefix + '|' + TimeSeriesId.toIdString(chart.timeSeriesId));
        labelSeries.forEach((serie) => serie.remove(false));

        let axis = isVertical ? this.chart.ref.xAxis : this.chart.ref.yAxis;
        axis.forEach((axe) => axe.options.plotLines
            .filter((plotLine) => plotLine.id === prefix + '|' + TimeSeriesId.toIdString(chart.timeSeriesId))
            .forEach((plotLine) => axe.removePlotLine(plotLine.id))
        );
    }

    get defaultChartOptions(): any {
        const _this = this;

        const chartOptions: any = {
            chart: {
                height: '660px',
                zoomType: 'xy',
                events: this.diagramService.getWatermark(false),
                resetZoomButton: {
                    theme: {
                        opacity: 0.3,
                        states: {
                            hover: {
                                opacity: 1
                            }
                        }
                    },
                    position: {
                        x: -25
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                ordinal: false,
                title: {
                    text: null
                },
                crosshair: true,
                dateTimeLabelFormats: _this.diagramService.getDateTimeLabelFormat(),
                plotLines: []
            },
            yAxis: [],
            lang: {
                decimalPoint: ','
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<small>{point.key}</small><table>',
                pointFormatter: function () {
                    const format = this.series.options.customData.unit.search('MWH') ? '1.3-3' : '1.2-2';
                    const valueFn = (value: number) => _this.decimalPipe.transform(value, format) + ' ' + this.series.options.customData.unit;
                    const translateFn = (name: string) => _this.translate.instant('marketPrices.diagram.' + name);
                    const data = this.series.options.customData;

                    const series = `<tr style="color: ${this.color}"><td style="padding-right: 10px"><b>${this.series.name}</b></td><td style="text-align: right"><b>${valueFn(this.y)}</b> </td></tr>`;
                    const min = _this.minSelected && data.min ? `<tr style="color: ${this.color}"><td style="padding: 0 10px; font-style: italic">${translateFn('min')}</td><td style="text-align: right"><b>${valueFn(data.min.seriesValue)}</b></td>` : '';
                    const max = _this.maxSelected && data.max ? `<tr style="color: ${this.color}"><td style="padding: 0 10px; font-style: italic">${translateFn('max')}</td><td style="text-align: right"><b>${valueFn(data.max.seriesValue)}</b></td>` : '';
                    const avg = _this.averageSelected && data.avg ? `<tr style="color: ${this.color}"><td style="padding: 0 10px; font-style: italic">${translateFn('avg')}</td><td style="text-align: right"><b>${valueFn(data.avg.seriesValue)}</b></td>` : '';
                    return `${series}${min}${max}${avg}`;
                },
                footerFormat: '</table>'
            },
            legend: {
                enabled: true
            },
            scrollbar: {
                enabled: false
            },
            time: {
                useUTC: false
            },
            boost: {
                enabled: true,
                useGPUTranslations: false
            },
            series: [],
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            exporting: {
                filename: 'MarketPrice',
                sourceWidth: 1180,
                sourceHeight: 660,
                scale: 1,
                chartOptions: {
                    title: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                    xAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        plotLines: {
                            width: '2px'
                        }
                    },
                    yAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '10px'
                        }
                    }
                },
                buttons: {
                    contextButton: {
                        align: 'left',
                        x: -10,
                        y: -10
                    }
                }
            }
        };

        if (this.resolution) {
            chartOptions.tooltip.xDateFormat = this.diagramService.getXDateFormat(this.resolution);
        }

        return chartOptions;
    }
}
