import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PeriodicalPodModel} from '../../../shared/dto/periodical.pod.model';
import {PodModel} from '../../../shared/dto/pod.model';
import {NgBDatePickerConvertService} from '../../../shared/services/ngb.datepicker.convert.service';
import {PodService} from '../../../shared/services/pod.service';


@Component({
    selector: 'jhi-pod-modal',
    templateUrl: './pod-modal.component.html',
    styles: []
})
export class PodModalComponent implements OnInit {

    @Input() model: PeriodicalPodModel;
    @Output() modalClose: EventEmitter<PeriodicalPodModel> = new EventEmitter();
    podList: PodModel[] = [];
    podResults: PodModel[] = [];

    constructor(private dateConverter: NgBDatePickerConvertService, public activeModal: NgbActiveModal,
                private http: HttpClient, private podService: PodService) {
    }

    ngOnInit() {
        if (this.model.startTime) {
            this.model.startTimeNgB = this.dateConverter.convertFromDate(this.model.startTime);
            this.model.endTimeNgB = this.dateConverter.convertFromDate(this.model.endTime);
        }

        this.http.get<PodModel[]>('api/pod').subscribe(r => this.podList = r);
    }

    save() {
        this.model.startTime = this.dateConverter.convertToDate(this.model.startTimeNgB);
        this.model.endTime = this.dateConverter.convertToDate(this.model.endTimeNgB);
        this.modalClose.emit(this.model);
    }

    searchPod(event) {
        this.podResults = this.podList.filter(r => r.podCode.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    }

    handleDropdown() {
        this.podResults = [];
        setTimeout(() => {
            this.podResults = this.podList.filter(r => true);
        }, 100);
    }

    podNotSelected() {
        return !this.model.pod || !this.model.pod.podCode;
    }
}
