<nav class="jh-navbar navbar navbar-expand-sm">
    <div class="jh-logo-container float-left">
        <a *ngIf="!isHedgeUrl.getValue()" (click)="deactivateSubMenu();collapseNavbar()" class="navbar-brand logo float-left"
           [routerLink]="['/']">
            <span class="logo-img"></span>
        </a>
        <a *ngIf="isAuthenticated() && isHedgeUrl.getValue()"
           class="navbar-brand logo float-right"
           [routerLink]="['/hedge-transaction']">
            <span class="cez-hedge-logo-img"></span>
        </a>
    </div>
    <div class="navbar-collapse collapse navbar-expand-sm main-navbar" id="navbarResponsive" [ngSwitch]="isAuthenticated()">
        <ul class="navbar-nav">
            <ng-container *ngFor="let item of menuItems">
                <li class="cez" *ngIf="item.isVisible" routerLinkActive="active-link">
                    <a *ngIf="item.url" class="nav-link" [routerLink]="['/'+item.url]"
                       (click)="toggleChild(item, $event)">{{item.text
                        | translate}}</a>
                    <a *ngIf="!item.url" href="javascript:void(0)" class="nav-link"
                       (click)="toggleChild(item, $event)">{{item.text
                        | translate}}</a>
                    <div class="dropdown-content" *ngIf="item.childrenVisible">
                        <nav mat-tab-nav-bar class="navbar-sub">
                            <a mat-tab-link *ngFor="let subitem of item.ChildLinks"
                               routerLinkActive
                               #rla="routerLinkActive"
                               [active]="rla.isActive"
                               [routerLink]="['/'+subitem.url]"
                               [hidden]="!subitem.isVisible"
                               [class.navbar-sub-active-link]="rla.isActive">
                                {{subitem.localizedTextFromDb ?
                                subitem.text : subitem.text | translate}}
                            </a>
                        </nav>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
    <div *ngIf="isAuthenticated() && !isHedgeUrl.getValue() && 'HEDGE_TRANSACTIONS_SCREEN' | hasPermission" class="jh-logo-container float-right">
        <a class="navbar-brand logo float-right"
           target="_blank"
           [routerLink]="['/hedge-transaction']">
            <span class="cez-hedge-logo-img"></span>
        </a>
    </div>
    <div class="navbar-collapse" *ngIf="isAuthenticated()">
        <ul class="navbar-nav">
            <li class="cez nav-icon-button" routerLinkActive="active-link" *ngIf="'HEDGE_TRANSACTIONS_SCREEN' | hasPermission">
                <a [routerLink]="['/hedge-transaction/cart']" [hidden]="!isCartUrl.getValue() && !isCartItemInMultipleDeliveryPeriods"
                   class="nav-link">
                    <i class="material-icons vertical-align--middle">shopping_cart</i>
                </a>
            </li>
            <li class="cez nav-icon-button notification-anchor" *ngIf="'HEDGE_TRANSACTIONS_SCREEN' | hasPermission">
                <a class="nav-link" [hidden]="!isActiveDailyPrices"
                   matTooltip="{{'dailyRates.toolTipForNavBar' | translate}}"
                   (click)="openDailyPricesModal()">
                    <div matBadge="12" matBadgeSize="small" matBadgeColor="warn" class="hide-text notification-container">
                        <mat-icon>notifications</mat-icon>
                    </div>
                </a>
            </li>
            <li ngbDropdown placement="bottom-right" class="dropdown nav-icon-button cursor--pointer">
                <a class="nav-link person-icon" ngbDropdownToggle href="javascript:void(0)" id="account-menu">
                    <span *ngIf="!imageUrl">
                         <mat-icon class="hide-text">person</mat-icon>
                    </span>
                    <span *ngIf="imageUrl">
                        <img [src]="imageUrl" class="profile-image" alt="Avatar">
                    </span>
                </a>

                <ul class="dropdown-menu dropdown-menu-admin-panel" ngbDropdownMenu>
                    <li disabled
                        class="dropdown-item border-bottom-1 non-selectable text-center">{{selectedCompany}}</li>
                    <li *ngIf="adminAccess">
                        <a class="dropdown-item" [routerLink]="['/admin']"  routerLinkActive="active"
                           (click)="collapseNavbar()">
                            <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>
                            <span label="Administration">{{'global.menu.admin.main'
                              | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" [routerLink]="['/company/selector']" routerLinkActive="active"
                           (click)="collapseNavbar()">
                            <i class="fa fa-fw fa-building-o" aria-hidden="true"></i>
                          <span label="Company Selector">{{'company-selector.title'
                            | translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="widgetAdmin">
                        <a class="dropdown-item" [routerLink]="['/home/admin']"  routerLinkActive="active"
                           (click)="collapseNavbar()">
                            <i class="fa fa-fw fa-wrench" aria-hidden="true"></i>
                            <span label="Settings"> {{'global.menu.account.settings'| translate}} </span>
                        </a>
                    </li>
                    <li *ngIf="currLang==='en'">
                        <a class="dropdown-item" (click)="updateLanguage('hu')">
                            <i class="fa fa-fw  fa-globe"></i>
                            <span class="">{{'login.changeLangToHun' | translate}}</span>
                            <span class="flag-hu vertical-center"></span>

                        </a>
                    <li *ngIf="currLang==='hu'">
                        <a class="dropdown-item" (click)="updateLanguage('en')">
                            <i class="fa fa-fw  fa-globe"></i>
                            <span>{{'login.changeLangToEn' | translate}}</span>
                            <span class="flag-en vertical-center"></span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item"  [routerLink]="['/password']" routerLinkActive="active"
                           (click)="collapseNavbar()">
                            <i class="fa fa-fw fa-clock-o" aria-hidden="true"></i>
                            <span  label="Password">  {{'global.menu.account.password'| translate}}  </span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="showDataUpdateTrackingInformation()">
                            <i class="fa fa-fw fa-refresh" aria-hidden="true"></i>
                            <span jhiTranslate="dataUpdateTracking.title">Latest Updates</span>
                        </a>
                    </li>
                    <li *ngIf="hasManageServerPermission">
                        <a class="dropdown-item" (click)="showInformation()" id="information">
                            <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>
                          <span  label="Information">  {{'global.menu.information.title'| translate}} </span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logout()" id="logout">
                            <i class="fa fa-fw fa-sign-out" aria-hidden="true"></i>
                            <span label="Sign out">  {{'global.menu.account.logout'| translate}}  </span>
                        </a>
                    </li>
                    <li disabled class="dropdown-item border-bottom-1 non-selectable"></li>
                    <li disabled class="dropdown-item non-selectable text-center">
                        <span>{{'global.menu.account.session' | translate}}</span>
                        <session-timer class="d-inline"></session-timer>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
