import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    Output,
    EventEmitter,
    OnInit,
    SimpleChange,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    LOCALE_ID,
    ChangeDetectorRef,
    TemplateRef,
    NgZone,
    inject,
} from '@angular/core';

import { CezCountdownConfig, CezCountdownStatus, CezCountdownEvent, CezCountdownEventAction, CezCountdownItem } from './interfaces';
import { formatDate } from '@angular/common';
import { COUNTDOWN_CONFIG } from './provide';
import {CezCountdownTimer} from "./cez-countdown.timer";
import {TranslateService} from '@ngx-translate/core';

/**
 * Original component from https://www.npmjs.com/package/ngx-countdown
 */
@Component({
    selector: 'cez-countdown',
    template: `
    @if (render) {
    <ng-container *ngTemplateOutlet="render; context: { $implicit: i }" />
    } @else {
    <span [innerHTML]="i.text"></span>
    }
  `,
    host: { '[class.count-down]': 'true' },
    styles: [
        `
      .count-down {
        font-variant-numeric: tabular-nums;
      }
    `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CezCountdownTimer],
})
export class CezCountdownComponent implements OnInit, OnChanges, OnDestroy {
    private locale = inject(LOCALE_ID);
    private timer = inject(CezCountdownTimer);
    private cdr = inject(ChangeDetectorRef);
    private ngZone = inject(NgZone);
    private defCog = inject(COUNTDOWN_CONFIG, { optional: true });

    private frequency = 1000;
    private _notify: { [key: number]: boolean } = {};
    private status: CezCountdownStatus = CezCountdownStatus.ing;
    private isDestroy = false;
    private _config!: CezCountdownConfig;
    private formatStr = this.translate.instant('offerClaim.scheduledQuotation.timer.format');
    i: CezCountdownItem = {};
    left = 0;

    constructor(private translate: TranslateService) {
    }

    @Input({ required: true })
    set config(i: CezCountdownConfig) {
        if (i.notify != null && !Array.isArray(i.notify) && i.notify > 0) {
            i.notify = [i.notify];
        }
        this._config = i;
    }
    get config(): CezCountdownConfig {
        return this._config;
    }
    @Input() render?: TemplateRef<{ $implicit: CezCountdownItem }>;
    @Output() readonly event = new EventEmitter<CezCountdownEvent>();

    /**
     * Start countdown, you must manually call when `demand: false`
     */
    begin(): void {
        this.status = CezCountdownStatus.ing;
        this.callEvent('start');
    }

    /**
     * Restart countdown
     */
    restart(): void {
        if (this.status !== CezCountdownStatus.stop) {
            this.destroy();
        }
        this.init();
        this.callEvent('restart');
    }

    /**
     * Stop countdown, must call `restart` when stopped, it's different from pause, unable to recover
     */
    stop(): void {
        if (this.status === CezCountdownStatus.stop) {
            return;
        }
        this.status = CezCountdownStatus.stop;
        this.destroy();
        this.callEvent('stop');
    }

    /**
     * Pause countdown, you can use `resume` to recover again
     */
    pause(): void {
        if (this.status === CezCountdownStatus.stop || this.status === CezCountdownStatus.pause) {
            return;
        }
        this.status = CezCountdownStatus.pause;
        this.callEvent('pause');
    }

    /**
     * Resume countdown
     */
    resume(): void {
        if (this.status === CezCountdownStatus.stop || this.status !== CezCountdownStatus.pause) {
            return;
        }
        this.status = CezCountdownStatus.ing;
        this.callEvent('resume');
    }

    private callEvent(action: CezCountdownEventAction): void {
        this.event.emit({ action, left: this.left, status: this.status, text: this.i.text! });
    }

    private init(): void {
        const config = (this.config = {
            demand: false,
            leftTime: 0,
            timezone: '+0000',
            negativeAllowed: false,
            removeDay: false,
            ...this.defCog,
            ...this.config,
        });
        const frq = (this.frequency = ~this.formatStr!.indexOf('S') ? 100 : 1000);
        this.status = config.demand ? CezCountdownStatus.pause : CezCountdownStatus.ing;

        this.getLeft();

        // bind reflow to me
        const _reflow = this.reflow;
        this.reflow = (count: number = 0, force: boolean = false) => _reflow.apply(this, [count, force]);

        if (Array.isArray(config.notify)) {
            config.notify.forEach((time: number) => {
                if (time < 1) {
                    throw new Error(`The notify config must be a positive integer.`);
                }

                time = time * 1000;
                time = time - (time % frq);
                this._notify[time] = true;
            });
        }

        this.timer.add(this.reflow, frq).start();

        this.reflow(0, true);
    }

    private destroy(): this {
        this.timer.remove(this.reflow);
        return this;
    }

    /**
     * Update the clock
     */
    private reflow(count: number = 0, force: boolean = false): void {
        if (this.isDestroy) {
            return;
        }

        const { status, config, _notify } = this;
        if (!force && status !== CezCountdownStatus.ing) {
            return;
        }

        let value = (this.left = this.left - this.frequency * count);
        if (!config.negativeAllowed && value < 1) {
            value = 0;
        }
        this.i = {
            value,
            text: this.format(value, this.formatStr!),
        };
        if (typeof config.prettyText === 'function') {
            this.i.text = config.prettyText(this.i.text!);
        }
        this.cdr.detectChanges();

        if (config.notify === 0 || _notify[value]) {
            this.ngZone.run(() => {
                this.callEvent('notify');
            });
        }

        if (!config.negativeAllowed && value === 0) {
            this.ngZone.run(() => {
                this.status = CezCountdownStatus.done;
                this.destroy();
                this.callEvent('done');
            });
        }
    }

    /**
     * Get the remaining frames of the countdown
     */
    private getLeft(): void {
        const { config, frequency } = this;
        let left = config.leftTime! * 1000;
        const end = config.stopTime;

        if (!left && end) {
            left = end - new Date().getTime();
        }

        this.left = left - (left % frequency);
    }

    /**
     * Format the value and handle negative values if allowed
     *
     * @param date
     * @param formatStr
     * @private
     */
    private format( date, formatStr): string {
        const isNegative = date < 0;
        const duration = Number(date || 0);
        const formattedDate = formatDate(new Date(Math.abs(date)), formatStr, 'hu', this.config.timezone || 'UTC');
        let day = Math.floor(duration / (1000 * 60 * 60 * 24));
        if (isNegative) {
            day = (day + 1) * -1;
        }

        let formattedString;
        if (this.config.removeDay && day == 0) {
            formattedString = formattedDate.split('; ')[1];
            return isNegative ? '- ' + formattedString : formattedString;
        } else {
            formattedString = formattedDate.replace('DD', '' + day);
        }
        return isNegative ? '- ' + formattedString : formattedString;
    }

    ngOnInit(): void {
        this.init();
        if (!this.config.demand) {
            this.begin();
        }
    }

    ngOnDestroy(): void {
        this.isDestroy = true;
        this.destroy();
    }

    ngOnChanges(changes: { [P in keyof this]?: SimpleChange } & SimpleChanges): void {
        if (!changes.config!.firstChange) {
            this.restart();
        }
    }
}
