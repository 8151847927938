export type CezCountdownFormatFn = (opt: CezCountdownFormatFnOption) => string;

export interface CezCountdownFormatFnOption {
  date: number;
  formatStr: string;
  timezone?: string;
}

export enum CezCountdownStatus {
  ing,
  pause,
  stop,
  done,
}

export interface CezCountdownConfig {
  /**
   * Start the counter on demand, must call `begin()` to starting, Default: `false`
   */
  demand?: boolean;

  /**
   * Calculate the remaining time based on the server, e.g: `10`,`5.5`, (Unit: seconds)
   */
  leftTime?: number;

  /**
   * Refers to counting down from local time to end time (Unit: Milliseconds second UNIX timestamp)
   */
  stopTime?: number;

  /**
   * Beautify text, generally used to convert formatted time text into HTML
   */
  prettyText?: (text: string) => string;

  /**
   * Should be trigger type `notify` event on the x second. When values is `0` will be trigger every time.
   */
  notify?: number[] | number;

  /**
   * A timezone offset (such as '+0430'), or a standard UTC/GMT.
   * When not supplied, uses the end-user's local system timezone, Default: `+0000`
   */
  timezone?: string;

  /**
   * Allows negative values so the countdown doesn't stop at 0 value.
   */
  negativeAllowed?: boolean;

  /**
   * If true, it removes the remaining amount of days from the countdown timer when the amount of days is less than 1.
   */
  removeDay?: boolean;
}

export type CezCountdownEventAction = 'start' | 'stop' | 'restart' | 'pause' | 'resume' | 'notify' | 'done';

export interface CezCountdownEvent {
  action: CezCountdownEventAction;
  status: CezCountdownStatus;
  left: number;
  text: string;
}

export interface CezCountdownItem {
  text?: string;
  value?: number;
}
